import {COLUMN_MAP} from "Component/Footer/Footer.config";
import getStore from "Util/Store";

export const renderColumns = (args, callback, instance) => {

    const state = getStore().getState();
    let {
        magenear_storelocator_general_enabled,
        magenear_storelocator_footer_column
    } = state.ConfigReducer;

    if (magenear_storelocator_general_enabled === "0") {
        return callback(...args);
    }

    //inject store-locator link to footer, NUMBER means column

    // const columnNumber = parseInt(magenear_storelocator_footer_column) - 1;
    // const targetColumn = COLUMN_MAP[columnNumber].items.filter(i => !(i.href && i.href === '/store-locator'));
    // COLUMN_MAP[columnNumber].items = targetColumn;

    const {categories = {}} = instance.props;
    const footerCategories = JSON.parse(categories);
    let footerColumnsCategories = footerCategories.concat(COLUMN_MAP);
    let columnNumber;
    columnNumber = Object.keys(footerColumnsCategories).filter(function(key) {return footerColumnsCategories[key]['columnIdentify'] === 'account'})[0];
    if (typeof columnNumber === 'undefined') {
        columnNumber = parseInt(magenear_storelocator_footer_column) - 1;
    }
    const targetColumn = footerColumnsCategories[columnNumber].items.filter(i => !(i.href && i.href === '/' + 'store-locator'));

    footerColumnsCategories[columnNumber].items = targetColumn;

    targetColumn.push({href:'/store-locator', title: __('Store Locator')});
    return callback(...args);

};
export default {
    'Component/Footer/Component': {
        'member-function': {
            renderColumns
        }
    }
};
