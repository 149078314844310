import {
    NewsletterSubscriptionComponent as SourceNewsletterSubscriptionComponent,
} from 'SourceComponent/NewsletterSubscription/NewsletterSubscription.component';

import './NewsletterSubscription.override.style';
import {ReactElement} from "Type/Common.type";
import {FieldType} from "Component/Field/Field.config";

export class NewsletterSubscriptionComponent extends SourceNewsletterSubscriptionComponent {
    renderActions(): ReactElement {
        return (
            <button
                type={FieldType.SUBMIT}
                block="Button"
                mods={{isHollow: true}}
                aria-label={__('Submit')}
            >
                {/*<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                {/*    <path d="M5 12H19" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>*/}
                {/*    <path d="M12 5L19 12L12 19" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>*/}
                {/*</svg>*/}
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 12L10 8L6 4" stroke="white" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round"/>
                </svg>

            </button>
        );
    }
};

export default NewsletterSubscriptionComponent;
