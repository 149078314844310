Mosaic.addPlugins([require('/usr/share/nginx/html/localmodules/tmv-pwa/packages/amasty-advancedreview/src/plugin/ComponentFooterComponent.plugin.js'),require('/usr/share/nginx/html/localmodules/tmv-pwa/packages/magenear-storelocator/src/plugin/ComponentFooterComponent.plugin.js'),require('/usr/share/nginx/html/localmodules/tmv-pwa/packages/magenear-trackorder/src/plugin/TrackOrder.plugin.js'),require('/usr/share/nginx/html/localmodules/tmv-pwa/packages/mirasvit-kb/src/plugin/ComponentFooterComponent.plugin.js')]);
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { Component } from 'react';

import CmsBlock from 'Component/CmsBlock';
import ContentWrapper from 'Component/ContentWrapper';
import Image from 'Component/Image';
import Link from 'Component/Link';
import NewsletterSubscription from 'Component/NewsletterSubscription';
import RenderWhenVisible from 'Component/RenderWhenVisible';
import { ReactElement } from 'Type/Common.type';
import { noopFn } from 'Util/Common';

import { COLUMN_MAP, NEWSLETTER_COLUMN, RENDER_NEWSLETTER } from './Footer.config';
import { FooterComponentProps, FooterRenderColumn, FooterRenderColumnItem } from './Footer.type';
import Decoration from "Component/Decoration/Decoration.component";
import './Footer.style';

import airtelMoneyIcon from 'Style/icons/payment/airtel_money.svg';
import amexIcon from 'Style/icons/payment/amex.svg';
import bhimIcon from 'Style/icons/payment/bhim.svg';
import cashreeIcon from 'Style/icons/payment/cashree.svg';
import ccIconIcon from 'Style/icons/payment/cc.svg';
import dcIcon from 'Style/icons/payment/dc.svg';
import dinnerClubIcon from 'Style/icons/payment/dinner_club.svg';
import discoverIcon from 'Style/icons/payment/discover.svg';
import freechargeIcon from 'Style/icons/payment/freecharge.svg';
import instamojoIcon from 'Style/icons/payment/instamojo.svg';
import jcbIcon from 'Style/icons/payment/jcb.svg';
import maestroIcon from 'Style/icons/payment/maestro.svg';
import mastercardIcon from 'Style/icons/payment/mastercard.svg';
import mobwikiIcon from 'Style/icons/payment/mobwiki.svg';
import netBankingIcon from 'Style/icons/payment/net_banking.svg';
import olaMoneyIcon from 'Style/icons/payment/ola_money.svg';
import paypalIcon from 'Style/icons/payment/paypal.svg';
import paytmIcon from 'Style/icons/payment/paytm.svg';
import payUIcon from 'Style/icons/payment/payU.svg';
import payzappIcon from 'Style/icons/payment/payzapp.svg';
import razorpayIcon from 'Style/icons/payment/razorpay.svg';
import RuPayIcon from 'Style/icons/payment/RuPay.svg';
import skrillIcon from 'Style/icons/payment/skrill.svg';
import stripeIcon from 'Style/icons/payment/stripe.svg';
import UpiIcon from 'Style/icons/payment/Upi.svg';
import visaIcon from 'Style/icons/payment/visa.svg';
import walletIcon from 'Style/icons/payment/wallet.svg';
import westernUnionIcon from 'Style/icons/payment/western_union.svg';
import yesPayIcon from 'Style/icons/payment/yes_pay.svg';

import mailIcon from 'Style/icons/social/mail.svg';
import whatsappIcon from 'Style/icons/social/whatsapp.svg';
import Logo from 'Component/Logo';
import ExpandableContent from "Component/ExpandableContent";

import newMastercardIcon from 'Style/icons/payment/new_mastercard.svg';
import newVisaIcon from 'Style/icons/payment/new_visa.svg';
import newPayPalIcon from 'Style/icons/payment/new_paypal.svg';
import newApplePayIcon from 'Style/icons/payment/new_applepay.svg';
import newGooglePayIcon from 'Style/icons/payment/new_googlepay.svg';

import { isMobile } from 'Util/Mobile';
import { isSignedIn } from 'Util/Auth/IsSignedIn';

import footerLogo from 'Style/icons/logos/footerLogo.svg';

/**
 * Page footer
 * @class Footer
 * @namespace Component/Footer/Component
 */
export class FooterComponent extends Component<FooterComponentProps> {
    static defaultProps: Partial<FooterComponentProps> = {
        copyright: '',
        isVisibleOnMobile: false,
        onItemClick: noopFn,
    };

    renderMap = {
        [RENDER_NEWSLETTER]: {
            render: this.renderNewsletterSubscriptionBlock.bind(this),
        },
    };

    shouldComponentUpdate(nextProps: FooterComponentProps):boolean {
        const {
            device: {
                isMobile,
            },
            isVisibleOnMobile,
            copyright,
            newsletterActive,
        } = this.props;

        const {
            device: {
                isMobile: nextIsMobile,
            },
            isVisibleOnMobile: nextIsVisibleOnMobile,
            copyright: nextCopyright,
            newsletterActive: nextNewsletterActive,
        } = nextProps;

        return isMobile !== nextIsMobile
            || isVisibleOnMobile !== nextIsVisibleOnMobile
            || copyright !== nextCopyright
            || newsletterActive !== nextNewsletterActive;
    }

    renderColumnItemContent(src?: string, title?: string): ReactElement {
        if (!src) {
            return title;
        }

        return (
            <Image
              mix={ { block: 'Footer', elem: 'ColumnItemImage' } }
              src={ src }
            />
        );
    }

    renderColumnItemLink(
        item: FooterRenderColumnItem,
        i: number,
    ): ReactElement {
        const { onItemClick } = this.props;
        const { href = '/', src, title } = item;

        if (title === 'Instagram' || title === 'Facebook' || title === 'Twitter' || title === 'YouTube') {
            return (
                <a
                    href={href as string}
                    block="Footer"
                    elem="ColumnItem"
                    rel="noopener noreferrer"
                    mods={src ? {type: 'image'} : undefined}
                    target="_blank"
                >
                    {this.renderColumnItemContent(src, title) }
                </a>
            )
        }


        return (
            <Link
                block="Footer"
                elem="ColumnItem"
                to={href}
                mods={src ? {type: 'image'} : undefined}
                key={i}
                aria-label={title}
                onClick={onItemClick}
            >
                {this.renderColumnItemContent(src, title) }
            </Link>
        );
    }

    renderColumnItem(item: FooterRenderColumnItem, i: number): ReactElement {
        const { render } = item;

        if (render && render in this.renderMap) {
            return this.renderMap[render as keyof typeof this.renderMap].render();
        }

        return this.renderColumnItemLink(item, i);
    }

    renderColumn(column: FooterRenderColumn, i?: number): ReactElement {
        const {
            title,
            columnActiveKey,
            columnIdentify,
            expandableContent,
            items,
            isItemsHorizontal,
            mods = {},
        } = column;

        const contentMods = isItemsHorizontal ? { direction: 'horizontal' } : {};

        if (columnActiveKey && !(columnActiveKey in this.props)) {
            return null;
        }

        return (
            <div className={columnIdentify} block="Footer" elem="Column" mods={ mods } key={ i }>

                {/*{columnIdentify === 'follow' ? (*/}
                {/*    this.renderFollowLogo()*/}
                {/*) : ''}*/}

                <h3 className={columnIdentify} block="Footer" elem="ColumnTitle">
                    { title }
                </h3>
                {/*<div*/}
                {/*  block="Footer"*/}
                {/*  elem="ColumnContent"*/}
                {/*  mods={ contentMods }*/}
                {/*>*/}
                {/*    { items.map(this.renderColumnItem.bind(this)) }*/}
                {/*</div>*/}

                {expandableContent ? (
                        <ExpandableContent
                            heading={title}
                            mix={{block: 'Footer', elem: 'Column'}}
                        >
                            {this.renderColumnItemAlone(items, contentMods)}
                        </ExpandableContent>
                    ) :
                    this.renderColumnItemAlone(items, contentMods)
                }

                {/*{columnIdentify === 'follow' ? (*/}
                {/*    this.renderContact()*/}
                {/*) : ''}*/}

                {columnIdentify === 'follow' ? (
                    this.renderFssai()
                ) : ''}

            </div>

        );
    }

    renderFssai () {
        return (
            <div id="fssai">
                <svg width="74" height="36" viewBox="0 0 74 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1967_36004)">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M46.3687 29.0017C43.9966 29.0017 45.543 23.7596 45.9493 22.5802C46.3949 21.3221 47.6005 18.7141 48.6359 17.9409C49.8284 17.0498 51.139 17.0629 52.5674 17.2594C52.8819 17.2988 54.258 17.6002 54.5594 17.7443L54.0614 20.2343L53.2489 21.8594C52.9737 22.3836 52.1743 23.681 51.8335 24.1266L50.2609 26.1579L48.7931 27.7567C48.282 28.2678 47.0894 29.0017 46.3555 29.0017H46.3687ZM53.2489 23.681C53.2489 23.9169 52.9213 25.5026 52.8426 25.8696C52.6985 26.5903 52.5674 27.298 52.4233 28.0319C52.1349 29.4735 52.5281 31.2165 54.2318 31.2296C55.8961 31.2296 57.3901 30.1943 58.4386 29.3031C58.8055 28.9886 58.9234 28.8444 59.2773 28.5037C59.3952 28.3858 59.8146 27.9009 59.8539 27.7698C59.8801 27.6781 60.0243 26.7738 60.0243 26.6297L59.1724 27.5208C59.1724 27.5208 59.1724 27.5339 59.1593 27.547C59.12 27.5863 59.1593 27.547 59.1069 27.5994C58.6351 28.0057 58.242 28.4513 57.6522 28.8444C57.1673 29.1852 56.132 29.906 55.5161 29.539C54.8608 29.159 55.2671 27.6257 55.3719 27.0752C55.4506 26.669 55.5554 26.2496 55.634 25.8171C56.0534 23.6941 56.5383 21.5842 56.9577 19.5004L57.7571 15.6737C57.3508 15.6737 56.9183 15.6082 56.6693 15.5951C55.0705 15.5164 53.393 15.4378 51.8073 15.6999C50.9031 15.8441 50.1036 16.0669 49.3698 16.3945C48.7931 16.6435 48.1117 17.0235 47.6137 17.4036L46.9584 17.9147C46.3031 18.4127 45.4513 19.2383 44.9533 19.8543C43.6297 21.5055 42.8171 23.0257 42.3323 25.1095C41.9653 26.669 41.8867 28.8838 42.9351 30.286C44.1277 31.8717 45.9493 31.2689 47.3778 30.4171C47.7709 30.1812 48.1379 29.8798 48.4917 29.6045C48.5834 29.539 48.6228 29.4866 48.7014 29.4211C49.1732 29.041 49.4877 28.7003 49.9202 28.2678L50.6672 27.4422C51.3748 26.6166 52.5281 24.9784 53.0654 23.9431C53.1309 23.8251 53.1178 23.7727 53.2227 23.7072L53.2489 23.681Z"
                              fill="#2E3192"/>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M15.8336 12.3188L13.3306 12.2925V10.8248H16.122C16.122 10.5627 16.2268 10.1957 16.2661 9.95982C16.6986 7.79746 16.6462 6.46073 18.0484 4.71773C19.0706 3.44653 20.8267 3.02716 22.4256 3.49895C22.7008 3.57758 22.9105 3.66932 23.1463 3.78726L23.8278 4.128L25.9902 1.99185C25.7805 1.97875 25.4267 1.91322 25.2432 1.88701C22.7401 1.75596 20.4991 1.93943 18.2843 3.27616C15.7943 4.78326 14.012 7.52225 13.3961 10.3399C13.265 10.9558 13.3437 10.8117 13.0553 10.7985H9.62178C9.4252 10.7985 9.4383 10.8248 9.4383 11.0082V12.3188H13.016C13.016 12.5284 12.7801 13.721 12.7277 14.0355C12.636 14.5991 12.5311 15.1495 12.4263 15.7261C12.0069 17.9409 11.64 20.3653 11.2075 22.567C11.0896 23.1437 10.9978 23.6941 10.9061 24.2707C10.8144 24.8473 10.6964 25.4109 10.5916 26.0006C10.0411 29.041 9.70041 33.274 6.22753 33.7064C4.82527 33.8768 3.90791 33.5099 2.74155 33.2478L2.30907 33.7589C2.16492 33.9423 1.99455 34.0865 1.85039 34.2831L1.06408 35.1742C0.972343 35.2922 0.933027 35.2922 0.919922 35.4757C2.72844 35.4757 4.55006 35.6984 6.27995 35.0694C6.42411 35.017 6.55516 34.9646 6.69932 34.9121C7.59047 34.5583 8.21952 34.1258 8.94031 33.5361L9.54315 32.9726C9.72662 32.7367 10.0674 32.3959 10.3819 31.9766C10.5654 31.7407 10.7095 31.5048 10.8668 31.2558C11.024 31.0199 11.1551 30.784 11.2992 30.5219C12.5967 28.1892 13.4223 25.0177 13.9465 22.4229C14.0775 21.7807 14.2217 21.0599 14.3397 20.4047C14.6673 18.4127 15.126 16.3289 15.4667 14.3501L15.8468 12.3188H15.8336Z"
                              fill="#2E3192"/>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M15.8337 12.3183H73.2607L73.8767 10.785H63.3401H62.829C62.3834 10.7326 39.5804 10.785 39.4362 10.785H17.5505C17.1312 10.785 16.4235 10.8243 16.1352 10.7981H13.3438V12.2921L15.8468 12.3183H15.8337Z"
                              fill="#F37032"/>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M63.7071 16.8661C64.1789 16.8661 64.6769 16.8268 65.0438 17.2069C65.6598 17.8359 65.2666 19.0416 65.1355 19.7624C65.0438 20.2866 64.9521 20.7453 64.8472 21.2564L63.9954 25.7253C63.825 26.5378 63.196 29.6699 63.196 30.2728C63.196 31.1246 63.2615 32.0289 63.8643 32.6317C64.4541 33.2214 64.9128 33.4049 65.8301 33.4049C67.0751 33.4049 68.5429 32.6186 69.4996 31.9764L71.6357 30.2859C71.6881 30.1679 71.6488 29.2375 71.6488 29.0278C71.4785 29.0671 71.3212 29.3423 71.1115 29.5127C70.4432 30.05 70.2073 30.3383 69.3161 30.8887C68.805 31.2032 67.6386 31.7668 67.1144 31.1246C66.6426 30.5349 66.8654 29.434 67.0096 28.7657L70.0893 13.5898C69.9058 13.6816 69.5913 13.7471 69.3816 13.8257C68.2153 14.2189 66.9179 14.5858 65.7515 14.979L62.1214 16.1323C61.7413 16.2371 61.5054 16.2764 61.4923 16.4861C61.3875 17.5738 61.3612 17.469 61.7413 17.351C61.8592 17.3117 62.0165 17.2855 62.1476 17.2462C62.449 17.1545 63.445 16.8924 63.7202 16.8924L63.7071 16.8661Z"
                              fill="#F37032"/>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M31.2846 19.8547C31.2846 21.0865 31.6646 21.8991 32.3854 22.6199L34.4561 24.9526C34.7968 25.3719 35.1113 25.5816 35.4258 26.2107C36.1466 27.6391 35.7928 29.1856 34.076 29.5787C33.6697 29.6705 33.3945 29.6442 33.0014 29.6311C32.4247 29.618 31.6515 29.3428 31.1273 29.1331C30.3148 28.8055 30.1969 28.6614 29.5416 28.3206C29.4106 28.5172 28.5718 29.3297 28.3883 29.5263L27.6807 30.2864C27.9952 30.4568 29.1484 30.7975 29.4761 30.863C31.8088 31.361 34.076 31.23 36.0418 29.867C36.5005 29.5525 37.1033 28.9497 37.3916 28.4779C37.5882 28.1634 37.7061 27.9668 37.8372 27.5736C38.2435 26.3286 37.9945 25.0312 37.2081 24.0614L34.8099 21.139C34.5216 20.772 34.0891 20.3264 33.8925 19.9333C33.6304 19.4222 33.4339 18.9635 33.5125 18.2296C33.6304 17.0632 34.9541 16.408 35.95 16.408C36.4087 16.408 36.7757 16.408 37.2213 16.5521C37.8765 16.788 38.5711 17.3122 38.5711 18.1117C38.5711 18.7014 38.5318 18.5703 38.4925 18.9111H39.4098C39.4885 18.7407 39.7243 17.653 39.7899 17.3909L40.1437 15.8183C39.803 15.7396 39.5671 15.5561 38.7808 15.4251C37.2213 15.163 35.7928 15.2809 34.3512 15.9231C33.8401 16.159 33.1586 16.5521 32.7655 16.9584L32.4379 17.286C31.8088 18.0068 31.2977 18.78 31.2977 19.8678L31.2846 19.8547Z"
                              fill="#2E3192"/>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M18.4156 19.9727C18.4156 20.7459 18.6908 21.6239 19.1626 22.2137L20.9056 24.1794C20.9711 24.2712 21.0104 24.2974 21.076 24.3629L21.9671 25.372C22.3603 25.7521 22.9238 26.6825 22.9238 27.4426C22.9238 28.2027 22.7272 28.7531 22.2161 29.1463C20.8532 30.1685 18.8743 29.487 17.5376 28.8187C17.3803 28.74 17.2624 28.6745 17.1182 28.5828C16.9871 28.5042 16.8299 28.3862 16.6857 28.3469L15.2835 29.7885C15.1 30.0375 14.8772 30.1554 14.8379 30.3258C14.9952 30.3651 15.1131 30.4175 15.2442 30.4699C15.3883 30.5224 15.5456 30.5748 15.6766 30.6141C18.0356 31.4135 21.1546 31.3611 23.1466 29.8933C23.6708 29.5001 24.0902 29.1332 24.4571 28.5828C25.44 27.1281 25.3745 25.4113 24.2998 24.0222L21.2463 20.3003C21.1022 20.143 21.0498 20.0251 20.958 19.8285C20.7483 19.3567 20.6173 19.0815 20.6173 18.4918C20.6173 15.8445 25.7152 15.661 25.7152 18.3083C25.7152 18.5704 25.6497 18.7146 25.6366 18.9242C26.6981 18.9242 26.5539 19.0029 26.6326 18.6097C26.6588 18.4656 26.685 18.3345 26.7243 18.2297C26.816 17.8758 27.1961 16.028 27.2747 15.8707C26.5277 15.4776 25.3483 15.3203 24.3261 15.3203C22.6879 15.3203 20.8008 16.0542 19.962 16.8929C19.831 17.0109 19.7392 17.0764 19.6213 17.1944C19.5427 17.273 19.5427 17.2992 19.4771 17.3778C18.966 17.9807 18.6122 18.518 18.468 19.3567C18.4418 19.5009 18.3894 19.8154 18.3894 19.9727H18.4156Z"
                              fill="#2E3192"/>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M65.6727 9.4745C65.4761 9.76281 64.8602 10.0904 64.4539 10.2215C64.1656 10.3263 63.6021 10.4574 63.2613 10.4836C63.0779 10.4836 62.9337 10.5229 62.7896 10.5229C62.7896 10.6671 62.7896 10.6802 62.8289 10.785L63.34 10.7588C63.4317 10.7195 64.5588 10.6802 65.4761 10.0249C66.0527 9.60555 65.6072 9.74971 66.2755 9.65797C66.4852 9.63176 66.5901 9.63176 66.7866 9.59244C69.8139 8.9765 71.0065 5.03183 71.0327 2.24042L71.0983 1.13959C71.1376 0.838166 71.0983 0.313958 71.0983 -0.0136719H71.0196C70.9803 0.471221 70.0892 1.82106 69.7877 2.24042C67.9137 4.33726 65.0043 6.39477 65.6727 9.44829V9.4745Z"
                              fill="#008E3C"/>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M63.6805 7.07715C64.4275 7.07715 65.0303 7.67999 65.0303 8.41388C65.0303 9.14777 64.4275 9.75061 63.6805 9.75061C62.9335 9.75061 62.3438 9.14777 62.3438 8.41388C62.3438 7.67999 62.9466 7.07715 63.6805 7.07715Z"
                              fill="#7F4007"/>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M68.9097 34.5322L68.1758 36H0.919922V35.7248C4.41901 35.9607 6.72553 35.4889 8.21952 34.5322H68.9097Z"
                              fill="#008E3C"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_1967_36004">
                            <rect width="72.9567" height="36" fill="white" transform="translate(0.919922)"/>
                        </clipPath>
                    </defs>
                </svg>
                <span>License No: 12216017000287</span>
            </div>
        )
    }

    renderColumns(): ReactElement {
        //@ts-ignore
        const {categories = {}} = this.props;
        const footerCategories = JSON.parse(categories);
        const footerColumnsCategories = footerCategories.concat(COLUMN_MAP);
        return (
            <ContentWrapper
                isNotSection
                wrapperMix={{block: 'Footer', elem: 'Columns'}}
                label=""
            >
                {footerColumnsCategories.map(this.renderColumn.bind(this))}
            </ContentWrapper>
        );
    }

    renderNewsletterSubscriptionBlock(): ReactElement {
        return <NewsletterSubscription key="NewsletterSubscription"/>;
    }

    renderCmsBlockWrapper(): ReactElement {
        const {footer_content: {footer_cms = undefined} = {}} = window.contentConfiguration || {};

        return (
            <div
                block="Footer"
                elem="CmsBlockWrapper"
                mix={{block: 'Footer', elem: 'Content'}}
            >
                <div
                    block="Footer"
                    elem="Columns"
                    mix={{block: 'ContentWrapper'}}
                >
                    <CmsBlock identifier={footer_cms}/>
                    {this.renderColumn({
                        ...NEWSLETTER_COLUMN,
                        mods: {isNewsletter: true},
                    })}
                </div>
            </div>
        );
    }

    renderContent(): ReactElement {
        const {footer_content: {footer_cms = undefined} = {}} = window.contentConfiguration || {};

        if (footer_cms) {
            return this.renderCmsBlockWrapper();
        }

        return (
            <div block="Footer" elem="Content">
                <div block="Footer" elem="Logo"><Logo src={footerLogo}/></div>
                {this.renderColumns()}
            </div>
        );
    }

    renderCopyrightContent(): ReactElement {
        const {copyright, device: {isMobile}} = this.props;

        return (
            <>
                {(isMobile) ? this.renderMobilePaymentMethods() : ''}
                <ContentWrapper
                    mix={{block: 'Footer', elem: 'CopyrightContentWrapper'}}
                    wrapperMix={{block: 'Footer', elem: 'CopyrightContent'}}
                    label=""
                >
                <span block="Footer" elem="Copyright">
                    {copyright}
                    {/*{ ' Powered by ' }*/}
                    { /* eslint-disable-next-line react/forbid-elements */}
                    {/*<a href="https://scandipwa.com">*/}
                    {/*    ScandiPWA*/}
                    {/*</a>*/}
                </span>
                    {(isMobile) ? '' : this.renderPaymentMethods()}
                </ContentWrapper>
            </>

        );
    }

    showPaymentMethods() {
        // @ts-ignore
        document.querySelector('.Footer-Payment-Methods-Wrapper').classList.add('show');
    }

    hidePaymentMethods() {
        // @ts-ignore
        document.querySelector('.Footer-Payment-Methods-Wrapper').classList.remove('show');
    }

    renderPaymentMethods() {

        const paymentMethods = [

            {
                src: visaIcon,
                title: __('Visa')
            },

            {
                src: mastercardIcon,
                title: __('Mastercard')
            },

            {
                src: maestroIcon,
                title: __('Maestro')
            },

            {
                src: RuPayIcon,
                title: __('RuPay')
            },

            {
                src: amexIcon,
                title: __('American Express')
            },

            {
                src: discoverIcon,
                title: __('Discover')
            },

            {
                src: dinnerClubIcon,
                title: __('Dinner Club')
            },

            {
                src: jcbIcon,
                title: __('JCB')
            },

            {
                src: ccIconIcon,
                title: __('Credit Card')
            },

            {
                src: dcIcon,
                title: __('Debit Card')
            },

            {
                src: netBankingIcon,
                title: __('Net Banking')
            },

            {
                src: UpiIcon,
                title: __('Upi')
            },

            {
                src: bhimIcon,
                title: __('Bhim')
            },

            {
                src: walletIcon,
                title: __('Wallet')
            },

            {
                src: paypalIcon,
                title: __('PayPal')
            },


            {
                src: stripeIcon,
                title: __('Stripe')
            },

            {
                src: razorpayIcon,
                title: __('RazorPay')
            },

            {
                src: paytmIcon,
                title: __('PayTM')
            },

            {
                src: payUIcon,
                title: __('PayU')
            },

            {
                src: payzappIcon,
                title: __('PayZapp')
            },

            {
                src: instamojoIcon,
                title: __('Instamojo')
            },

            {
                src: cashreeIcon,
                title: __('Cashree')
            },

            {
                src: mobwikiIcon,
                title: __('Mobwiki')
            },

            {
                src: freechargeIcon,
                title: __('Free Charge')
            },

            {
                src: airtelMoneyIcon,
                title: __('Airtel Money')
            },

            {
                src: olaMoneyIcon,
                title: __('Ola Money')
            },

            {
                src: yesPayIcon,
                title: __('Yes Pay')
            },

            {
                src: skrillIcon,
                title: __('Skrill')
            },

            {
                src: westernUnionIcon,
                title: __('Western Union')
            },
        ];

        return (
            <div block="Footer-Payment-Methods-Wrapper">
                <span
                    onMouseEnter={this.showPaymentMethods.bind(this)}
                    onMouseLeave={this.hidePaymentMethods.bind(this)}
                >{__('Payment Methods')}</span>
                <div block="Footer-Payment-Methods">
                    {
                        paymentMethods.map(
                            (method, i) => {
                                return (
                                    <img
                                        block='payment-methods-info'
                                        src={method.src || ''}
                                        alt={method.title}
                                        title={method.title}
                                        loading="lazy"
                                    />
                                )
                            }
                        )
                    }
                </div>
            </div>
        );
    }

    renderMobilePaymentMethods() {

        const paymentMethods = [

            {
                src: newMastercardIcon,
                title: __('Mastercard')
            },
            {
                src: newVisaIcon,
                title: __('Visa')
            },
            {
                src: newPayPalIcon,
                title: __('PayPal')
            },
            {
                src: newApplePayIcon,
                title: __('ApplePay')
            },
            {
                src: newGooglePayIcon,
                title: __('GooglePay')
            }


        ];

        return (
            <div block="Footer-Payment-Methods-Wrapper">
                <div block="Footer-Payment-Methods">
                    <span>We accept Netbanking, all major credit cards. We also accept orders with cash payment</span>
                    <div block="Footer-Payment-Method">
                        {
                            paymentMethods.map(
                                (method, i) => {
                                    return (
                                        <img
                                            block='payment-methods-info'
                                            src={method.src || ''}
                                            alt={method.title}
                                            title={method.title}
                                            loading="lazy"
                                        />
                                    )
                                }
                            )
                        }
                    </div>
                </div>
            </div>
        );
    }

    renderFollowLogo() {
        return (
            <>
                <Logo src={footerLogo} />
                {/*<div className='divah-brand-info-wrapper'>*/}
                <p className='divah-brand-info'>
                    Divah is a Fashion Jewellery E-commerce Brand based in India, that aims to provide affordable women’s jewellery of all kinds at its best.
                </p>
                {/*</div>*/}
                <div className="footer-logo-divider"></div>
            </>
        )
    }

    renderColumnItemAlone(items: any, contentMods: {}) {
        return (
            <div
                block="Footer"
                elem="ColumnContent"
                mods={contentMods}
            >
                {items.map(this.renderColumnItem.bind(this))}
            </div>
        )
    }

    renderContact() {
        return (
            <div block="Footer" elem="Contact">
                <h2 className="Footer-ColumnTitle follow">Contact Us</h2>
                <a href="mailto:cs@divah.in">
                    <img
                        style={{width: '16px'}}
                        alt='mail'
                        src={mailIcon}
                    />
                    cs@divah.in
                </a>
                {/*<p>|</p>*/}
                <a href="https://api.whatsapp.com/send?phone=919165091680" target="_blank">
                    <img
                        style={{width: '20px'}}
                        alt='whatsapp'
                        src={whatsappIcon}
                    />
                    +91 916 50 916 80
                </a>
            </div>
        )
    }
    render(): ReactElement {

        setTimeout(function () {
            const myAccountButton = document.getElementById('myAccount');
            const myAccountOverlay = document.querySelector('.Overlay.MyAccountOverlay');
            if (!myAccountOverlay && myAccountButton && !isSignedIn()) {
                // @ts-ignore
                // myAccountButton.click();
            }
        }, 500);

        // console.clear();
        // console.log(this.props);

        const element = document.body;
        // @ts-ignore
        if (element.getAttribute('listener') !== 'true') {
            // @ts-ignore
            element.setAttribute('listener', 'true');
            // @ts-ignore
            element.addEventListener('click', function (data) {
                const elementClicked = data.target;
                // @ts-ignore
                if (elementClicked?.id === 'pp-heading-mobile' && isMobile.any()) {
                    // @ts-ignore
                    elementClicked?.classList.toggle('active');
                }
                // @ts-ignore
                if (!!(elementClicked?.className) && elementClicked?.offsetParent?.id ==='cms-page-privacy-policy-toc') {
                    // @ts-ignore
                    let scrollDiv = document.getElementById(elementClicked?.className)?.offsetTop + 220;
                    if (isMobile.any()) {
                        scrollDiv -= 40;
                    }
                    window.scrollTo({ top: scrollDiv, behavior: 'smooth'});
                    document.getElementById('pp-heading-mobile')?.classList.remove('active');
                }
            });
        }

        const { isVisibleOnMobile, device } = this.props;

        if (!isVisibleOnMobile && device.isMobile) {
            return null;
        }

        if (isVisibleOnMobile && !device.isMobile) {
            return null;
        }

        return (
            <RenderWhenVisible>
                <footer block="Footer" aria-label="Footer">
                    { this.renderContent() }
                    { this.renderCopyrightContent() }
                </footer>
            </RenderWhenVisible>
        );
    }
}

export default FooterComponent;
